import React, { useState, useEffect } from 'react';
import UsageCharts from 'shared-components/lib/components/UsageCharts';

const loginUrl = 'https://api.challengerinsights.gg/login';
const dataUrl = 'https://api.challengerinsights.gg/usage-data';

function Admin() {
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password }),
            });
    
            const data = await response.json();
            if (data.success) {
                console.log('Logged in');
                localStorage.setItem('token', data.token);
                setToken(data.token);
            } else {
                console.log('Incorrect password');
            }
        }
    };
    
    const fetchData = async () => {
        if (!token) return;

        const response = await fetch(dataUrl, {
            headers: { 'Authorization': token },
        });
        if (response.status === 403) {
            console.log('Unauthorized');
            setToken('');
            localStorage.removeItem('token');
        } else if (response.status !== 200) {
            console.log('Error fetching data', await response.text());
        } else {
            const data = await response.json();
            setData(data);
            console.log('Fetched data', data);
        }
    };

    if (!data) {
        return (
            <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    style={{ padding: '10px', borderRadius: 6, border: 'none', outline: 'none' }}
                />
            </div>
        );
    }
    return (
        <div style={{ width: '80vw', minHeight: '100vh', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
            <UsageCharts data={data} />
        </div>
    );
}

export default Admin;
