import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Socials from '../Socials';

const logDownloadUrl = 'https://api.challengerinsights.gg/log-download';

function Download() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      function handleResize() {
          setWindowWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDownloadClick = async () => {
    try {
      await fetch(logDownloadUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
    } catch (error) {
      console.error('Error logging download:', error);
    }

    const link = document.createElement('a');
    link.href = 'https://github.com/ChallengerInsights/challenger-insights-releases/releases/latest/download/Challenger-Insights-Installer.exe';
    link.setAttribute('download', 'Challenger-Insights-Installer.exe');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const linkStyle = {
      color: 'white',
      textDecoration: 'underline',
      textDecorationColor: 'white',
      fontSize: 17,
  };

  return (
    <div className="Landing" style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontFamily: '"Lora", cursive', textAlign: 'center' }}>
        <a style={{ ...linkStyle, fontSize: windowWidth > 768 ? 40 : 24, margin: '0 0 0 0' }} href="#" onClick={handleDownloadClick}> Download (Windows)</a>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/riotgamesfist.png" alt="" style={{ height: 25, width: 'auto', marginRight: 5 }} draggable={false} />
          <p style={{ fontFamily: '"Lora", cursive', color: 'white', fontSize: 12 }}>
            Riot Games Compliant
          </p>
        </div>
        <div style={{ marginTop: 50 }}>
          <Socials />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 40, marginTop: 75 }}>
          <Link style={linkStyle} to="/show-me" target="_blank">Show me</Link>
          <Link style={linkStyle} to="/blog" target="_blank">Blog</Link>
        </div>
        <div style={{ position: 'absolute', left: '50%', bottom: '8%', transform: 'translate(-50%, 0%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <a href="https://www.op.gg/summoners/na/vijnej-NA1" target="_blank" rel="noopener noreferrer">
              <img src="opgg_icon.png" alt="opgg icon" style={{ height: 20, width: 20 }} draggable={false} />
          </a>
          <a href="https://www.linkedin.com/in/rajendey/" target="_blank" rel="noopener noreferrer">
              <img src="linkedin_icon.png" alt="linkedin icon" style={{ height: 20, width: 20, marginLeft: 5 }} draggable={false} />
          </a>
        </div>
        <div style={{ position: 'absolute', left: '70%', bottom: '8%', transform: 'translate(-50%, 0%)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
          <Link to="/terms-of-service" target="_blank" style={{...linkStyle, fontSize: 10}}>Terms of Service</Link>
          <Link to="/privacy-policy" target="_blank" style={{...linkStyle, fontSize: 10}}>Privacy Policy</Link>
        </div>
        <div style={{ position: 'absolute', left: '50%', bottom: '2%', transform: 'translate(-50%, 0%)', width: '60%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          <p style={{ fontSize: 8 }}>Challenger Insights isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.</p>
        </div>
      </div>
    </div>
  );
}

export default Download;
