import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";

import Slides from './slides/Slides';
import Admin from './Admin';
import GameSummaryPage from './GameSummaryPage';
import Blog from './blog/Blog';
import BlogPost from './blog/BlogPost';
import ShowMe from './ShowMe';
import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsOfService from './legal/TermsOfService';

function App() {
  return (
    <Router>
        <div className="App">
            <Analytics />
            <Routes>
                <Route exact path="/" element={<Slides />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/terms-of-service" element={<TermsOfService />} />
                <Route exact path="/admin" element={<Admin />} />
                <Route exact path="/show-me" element={<ShowMe />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route path="/blog/:blogId" element={<BlogPost />} />
                <Route path="/game/:gameId" element={<GameSummaryPage />} />
            </Routes>
        </div>
    </Router>
  );
}

export default App;
