import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

const TermsOfService = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        import(`./terms-of-service.md`)
        .then((module) => {
            fetch(module.default)
                .then((response) => response.text())
                .then((text) => setContent(text))
                .catch((error) => console.error('Error fetching:', error));
        });
    }, []);

    return (
        <div style={{ width: '100%', maxWidth: 1200, height: '100%', padding: '6% 8% 6% 8%', margin: '0 auto', color: '#dfdfdf', fontFamily: 'Arial', lineHeight: 1.8 }}>
            <Markdown>{content}</Markdown>
        </div>
    );
};

export default TermsOfService;
