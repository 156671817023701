import React from 'react';

function Slogan() {
  return (
    <div className="Slogan" style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1 style={{ margin: '0 0 10px 0' }}>Think like a challenger.</h1>
      <h1 style={{ margin: '10px 0 0 0' }}>Play like a challenger.</h1>
    </div>
  );
}

export default Slogan;
