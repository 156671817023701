import React from 'react';

function Splash() {
    const backgroundStyle = {
      backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)), url("/championship-zed.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  
    const textStyle = {
      color: 'white',
      fontSize: '24px',
      fontFamily: '"Lora", cursive',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
      textAlign: 'center',
      userSelect: 'none'
    };
  
    return (
      <div className="Splash" style={backgroundStyle}>
          <h1 style={textStyle}>Challenger Insights</h1>
      </div>
    );
  }
  

export default Splash;
