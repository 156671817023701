import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';

const BlogPost = () => {
    const { blogId } = useParams();

    const [postContent, setPostContent] = useState('');

    useEffect(() => {
        import(`../blog-posts/${blogId}.md`)
            .then((module) => {
                fetch(module.default)
                    .then((response) => response.text())
                    .then((text) => setPostContent(text))
                    .catch((error) => console.error('Error fetching blog post:', error));
            });
    }, [blogId]);

    return (
        <div style={{ width: '100%', height: '100%', maxWidth: 1400, padding: '3% 8% 3% 8%', margin: '0 auto', color: '#bfbfbf', fontFamily: 'Arial', lineHeight: 1.8 }}>
            <Markdown>{postContent}</Markdown>
        </div>
    );
};

export default BlogPost;
