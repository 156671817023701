import React from 'react';

const Socials = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
            <img src='discord_logo.png' alt="Discord" style={{ width: 'auto', height: 20, cursor: 'pointer' }} draggable={false} onClick={() => {window.open('https://discord.gg/dcT6m9XmPW')}} />
            <img src='x_logo.png' alt="X" style={{ width: 'auto', height: 20, cursor: 'pointer' }} draggable={false} onClick={() => {window.open('https://x.com/ch_insights')}} />
        </div>
    );
}

export default Socials;
