import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityDragonProvider, GameDataProvider, SummaryPage, setupCharts } from 'shared-components'
import { getGame } from 'shared-components/lib/api/api';

setupCharts();

const GameSummaryPage = () => {
    const { gameId } = useParams();
    const [gameData, setGameData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fadeIn, setFadeIn] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const gameData = await getGame(gameId);
                // console.log('gameData', gameData);
                if (!gameData) {
                    setError('Game not found');
                    return;
                }
                setGameData(gameData);
            } catch (err) {
                setError('Error fetching data');
            } finally {
                setTimeout(() => {
                    setLoading(false);
                    setTimeout(() => {
                        setFadeIn(true);
                    }, 50);
                }, 1000);
            }
        };

        fetchGameData();
    }, [gameId]);

    if (isMobile) {
        return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>Challenger Insights isn't formatted for mobile.</div>;
    }
    if (error) {
        return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{error}</div>;
    }
    return (
        <CommunityDragonProvider>
            <GameDataProvider gameData={gameData}>
                {
                    loading && <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loading...</div>
                }
                <div 
                    style={{
                        width: '100%',
                        height: '100%',
                        paddingTop: 50,
                        opacity: fadeIn ? 1 : 0,
                        transition: 'opacity 0.2s ease-in-out',
                        display: loading ? 'none' : 'block',
                    }}
                >
                    <SummaryPage gameId={gameId} />
                </div>
            </GameDataProvider>
        </CommunityDragonProvider>
    );
};

export default GameSummaryPage;
