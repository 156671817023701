import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '5%' }}>
            <Link to="/blog/1" style={{ color: 'white', fontSize: 28, textDecorationThickness: 1 }}>1. How To Train Your League Engine</Link>
        </div>
    );
};

export default Blog;
