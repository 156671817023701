import React, { useEffect, useState } from 'react';
import './Slides.css';

import Splash from './Splash';
import Slogan from './Slogan';
import Download from './Download';

function Slides() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [visible, setVisible] = useState(false);

  const slides = [Splash, Slogan, Download];
  const durations = [2000, 1000];
  const animationDuration = 500;

  useEffect(() => {
    const initialFadeIn = setTimeout(() => {
      setVisible(true);
    }, animationDuration);

    if (currentSlide < durations.length) {
      const timer = setTimeout(() => {
        setVisible(false)
        setTimeout(() => {
          if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
            setVisible(true);
          }
        }, animationDuration);
      }, durations[currentSlide]);

      return () => clearTimeout(timer);
    }

    return () => clearTimeout(initialFadeIn);
  }, [currentSlide, durations]);

  const renderSlide = () => {
    const Slide = slides[currentSlide];
    return <Slide />;
  };

  return (
    <div className="Slides" style={{ width: '100%', height: '100%', background: 'radial-gradient(circle, rgba(2, 0, 31, 1) 70%, rgba(0, 0, 0, 1) 100%)' }}>
      <div className={`slide ${visible ? 'fade-in' : 'fade-out'}`} style={{ width: '100%', height: '100%' }}>
        {renderSlide()}
      </div>
    </div>
  );
}

export default Slides;
